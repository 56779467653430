<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - Top 10 App flows</h3>
		</div>

		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<div v-if="error" class="pt-5">
					<b-alert show variant="warning" class="m-0">
						<span class="svg-icon-danger">
							<inline-svg src="/media/svg/icons/Code/Warning-2.svg"></inline-svg>
						</span>
						{{ errorMsg }}
					</b-alert>
				</div>
				<div v-if="!error" class="row">
					<div class="col-md-6">
						<apexchart type="area" :height="height" :options="aoptions" :series="aoptions.series" />
					</div>
					<div class="col-md-6">
						<apexchart type="pie" :height="height" :options="poptions" :series="poptions.series" />
					</div>
				</div>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
const date = new Date();
import { KMG } from '@/helpers';
const offset = date.getTimezoneOffset() * 60000;
export default {
	props: ['name', 'id', 'siteid'],
	name: 'SolarwindsNetFlowNBAR',
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	data() {
		return {
			loaded: false,
			error: false,
			height: 350,
			errorMsg: '',
			aoptions: {},
			poptions: {},
			data: [],
		};
	},
	methods: {
		processData(d) {
			this.data = d;
			var series = [];

			// AREA CHART
			Object.keys(d.data.data.bytesData).forEach(app => {
				var ds = [];
				d.data.data.bytesData[app].forEach(r => {
					ds.push({ x: new Date(r[0]), y: r[1] });
				});
				series.push({ name: app, data: ds });
			});
			this.aoptions = {
				series: series,
				chart: {
					type: 'area',
					stacked: false,
					height: 350,
					zoom: {
						enabled: true,
					},
					toolbar: {
						show: false,
					},
				},
				dataLabels: {
					enabled: false,
				},
				markers: {
					size: 0,
				},
				fill: {
					type: 'gradient',
					gradient: {
						shadeIntensity: 1,
						inverseColors: false,
						opacityFrom: 0.45,
						opacityTo: 0.05,
						stops: [20, 100, 100, 100],
					},
				},
				yaxis: {
					labels: {
						style: {
							colors: '#8e8da4',
						},
						offsetX: 0,
						formatter: function(val) {
							return KMG(val) + 'B';
						},
					},
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
				},
				xaxis: {
					type: 'datetime',
					tickAmount: 4,
					labels: {
						rotate: -15,
						rotateAlways: true,
						formatter: function(val, timestamp) {
							return (
								new Date(timestamp).toISOString().split('T')[0] +
								' ' +
								new Date(timestamp)
									.toISOString()
									.split('T')[1]
									.substring(0, 8)
							);
						},
					},
				},
				title: {
					text: 'App Bytes',
					align: 'center',
					offsetX: 14,
				},
				tooltip: {
					shared: true,
				},
				legend: {
					position: 'bottom',
					horizontalAlign: 'center',
					offsetX: -10,
				},
			};

			// PIE CHART
			var pseries = [];
			var plabels = [];
			Object.keys(d.data.data.bytesSum).forEach(app => {
				plabels.push(app);
				pseries.push(d.data.data.bytesSum[app]);
			});

			this.poptions = {
				series: pseries,
				chart: {
					width: 380,
					type: 'pie',
				},
				title: {
					text: 'Total App Bytes by Percentage',
					align: 'center',
				},
				labels: plabels,
				tooltip: {
					y: {
						formatter: function(val, opts) {
							return KMG(val) + 'B';
						},
					},
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: 'bottom',
							},
						},
					},
				],
			};
		},
	},
	created() {
		this.$http
			.get(`swnbarnetflow/${this.siteid}/${this.id}`)
			.then(resp => {
				this.processData(resp);
				this.loaded = true;
			})
			.catch(e => {
				if (e.data && e.data == 'No data for selected range') {
					this.error = true;
					this.errorMsg = e.data;
				} else {
					this.errorMsg = 'There was an error retrieving data for this device.  ' + e;
					this.error = true;
				}
				this.loaded = true;
			});
	},
};
</script>

<style></style>
